import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const ListContainer = styled.div`
  flex: ${props => props.isOpen ? 0.5 : 0.025};
  padding: 20px;
  background-color: #e0e0e0;
  overflow-y: auto;
  transition: flex 0.3s ease-in-out;
  position: relative;

  @media (orientation: portrait) {
    flex: ${props => props.isOpen ? 0.5 : 0.025};
    height: 50vh;
  }
`;

const PlanetItem = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const MenuButton = styled.button`
  position: absolute;
  top: 5px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  transition: right 0.3s ease-in-out;
  
  right: ${props => props.isOpen && props.isWideScreen ? '10px' : '50%'};
  transform: ${props => props.isOpen && props.isWideScreen ? 'none' : 'translateX(50%)'};

  svg {
    width: 24px;
    height: 24px;
  }
`;

const openEyePath = "M1 12C1 12 5 5 12 5C19 5 23 12 23 12C23 12 19 19 12 19C5 19 1 12 1 12Z";
const closedEyePath = "M1 12C1 12 5 12 12 12C19 12 23 12 23 12C23 12 19 12 12 12C5 12 1 12 1 12Z";

const AnimatedEyeIcon = ({ isOpen }) => {
  const { d, pupilScale, pupilOpacity } = useSpring({
    d: isOpen ? openEyePath : closedEyePath,
    pupilScale: isOpen ? 1 : 0,
    pupilOpacity: isOpen ? 1 : 0,
    config: { mass: 10, tension: 500, friction: 120 },
  });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <animated.path
        d={d}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.circle
        cx="12"
        cy="12"
        r="3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          transform: pupilScale.to(s => `scale(${s})`),
          opacity: pupilOpacity,
          transformOrigin: "12px 12px",
        }}
      />
    </svg>
  );
};

const CategoryContainer = styled.div`
  margin-bottom: 10px;
`;

const CategoryHeader = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const CategoryIcon = styled.span`
  margin-right: 5px;
  transition: transform 0.3s ease;
  display: inline-block;
  transform: ${props => props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

const CategoryContent = styled(animated.div)`
  overflow: hidden;
`;

const Category = ({ name, projects, onSelectPlanet }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState('auto');
  const contentRef = useRef(null);

  const updateHeight = useCallback(() => {
    if (contentRef.current) {
      const height = isOpen ? contentRef.current.scrollHeight : 0;
      setContentHeight(height);
    }
  }, [isOpen]);

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [updateHeight, projects]);

  const contentProps = useSpring({
    height: contentHeight,
    opacity: isOpen ? 1 : 0,
    config: { tension: 300, friction: 20 },
  });

  return (
    <CategoryContainer>
      <CategoryHeader onClick={() => setIsOpen(!isOpen)}>
        <CategoryIcon isOpen={isOpen}>&gt;</CategoryIcon>
        {name}
      </CategoryHeader>
      <CategoryContent style={contentProps}>
        <div ref={contentRef}>
          {projects.map((project) => (
            <PlanetItem key={project.id} onClick={() => onSelectPlanet(project)}>
              {project.name}
            </PlanetItem>
          ))}
        </div>
      </CategoryContent>
    </CategoryContainer>
  );
};

function PlanetList({ projects, onSelectPlanet, isPortrait }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 400);
  const [categorizedProjects, setCategorizedProjects] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 400);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const categorize = () => {
      const categorized = {};
      projects.forEach(project => {
        const category = project.category || 'Uncategorized';
        if (!categorized[category]) {
          categorized[category] = [];
        }
        categorized[category].push(project);
      });

      for (const [key, value] of Object.entries(categorized)) {
        value.sort((a, b) => {
          return a['name'].localeCompare(b['name']);
        });
        for (const project in value){
          value[project]['name'] = value[project]['name'].replaceAll('!','');

        }
      }      
      
      const sortedCategories = Object.keys(categorized).sort((a, b) => {
        if (a === 'Uncategorized') return 1;
        if (b === 'Uncategorized') return -1;
        return a.localeCompare(b);
      });

      const orderedCategorized = {};
      sortedCategories.forEach(category => {     
        orderedCategorized[category.replaceAll('!','')] = categorized[category];
      });

      setCategorizedProjects(orderedCategorized);
    };

    categorize();
  }, [projects]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ListContainer isOpen={isOpen}>
      <MenuButton onClick={toggleMenu} isOpen={isOpen} isWideScreen={isWideScreen}>
        <AnimatedEyeIcon isOpen={isOpen} />
      </MenuButton>
      {isOpen && (
        <>
          <h2>Projects</h2>
          {Object.entries(categorizedProjects).map(([category, categoryProjects]) => (
            <Category 
              key={category} 
              name={category} 
              projects={categoryProjects} 
              onSelectPlanet={onSelectPlanet}
            />
          ))}
        </>
      )}
    </ListContainer>
  );
}

export default PlanetList;