// PlanetInfo.js
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const InfoContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f0f0f0;
  overflow-y: auto;
  width: 25vw;

  @media (orientation: portrait) {
    height: 50vh;
  }
`;

const StyledMarkdown = styled(ReactMarkdown)`

  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }
  
  p {
    line-height: 1.6;
    text-align: justify;
  }
  
  ul, ol {
    padding-left: 20px;
  }

  li {
    list-style-type: circle;
    margin-left: 10px;
    padding: 2px;
 }

  img {
    max-width: 100%;
    max-height: 25vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  table {
    margin-left: auto;
    margin-right: auto;
  }

  a.buttonLink {
        display: block;
        overflow-y: auto;
        max-width: 80vw;
        margin: auto; /* Adjust the top margin to control vertical positioning */
        background-color: black;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
    }


`;

function PlanetInfo({ planet, isPortrait }) {
    const containerRef = useRef(null);
  
    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }, [planet]);
  
    return (
      <InfoContainer ref={containerRef}>
        <StyledMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        >
          {planet.info}
        </StyledMarkdown>
      </InfoContainer>
    );
  }
  
  export default PlanetInfo;