import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const SolarSystemContainer = styled.div`
  flex: 1;
  background-color: #000;
  position: relative;
  overflow: hidden;

  @media (orientation: portrait) {
    flex: 7;
  }
`;

const CameraView = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Star = styled.div`
  width: ${props => props.radius * 2}px;
  height: ${props => props.radius * 2}px;
  background-color: #FDB813;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const Planet = styled(animated.div)`
  width: ${props => props.radius * 2}px;
  height: ${props => props.radius * 2}px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  cursor: pointer;
`;

function SolarSystem({ projects, selectedPlanet, onSelectPlanet, isPortrait }) {
    const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0, scale: 1 });
    const [scaleAdjust, setScaleAdjust] = useState(0);
    const containerRef = useRef(null);
    const planetsRef = useRef({});
    const touchStartRef = useRef(null);
  
    const cameraSpring = useSpring({
      to: { x: -cameraPosition.x, y: -cameraPosition.y, scale: cameraPosition.scale },
      config: { tension: 120, friction: 14 }
    });

  useEffect(() => {
    let animationFrameId;

    const animate = () => {
      projects.forEach((project) => {
        const planet = planetsRef.current[project.id];
        if (project.id && selectedPlanet.id === 0) {
          setCameraPosition({ x: 0, y: 0, scale: 1 + scaleAdjust });
        }
        if (!planet) return;

        var angle = (Date.now() / (project.orbitSpeed*project.id)) % 360;
        if (project.hasOwnProperty("orbitCCW")) {
          if (project["orbitCCW"] === "true") {
            angle = 360 - angle;
          }
        }
        const radius = project.id * 100;
        const x = (Math.cos(angle * Math.PI / 180) * radius) - project.radius;
        const y = (Math.sin(angle * Math.PI / 180) * radius) - project.radius;
        planet.style.transform = `translate(${x}px, ${y}px)`;

        if (project.id === selectedPlanet.id) {
          setCameraPosition({ x: x*(1.5+scaleAdjust), y: y*(1.5+scaleAdjust), scale: 1.5 + scaleAdjust });
        }
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [projects, selectedPlanet, scaleAdjust]);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = (event) => {
      event.preventDefault();
      setScaleAdjust(prevAdjust => {
        const newAdjust = prevAdjust + event.deltaY * -0.001;
        return Math.min(Math.max(newAdjust, -0.5), 1.5);
      });
    };

    const handleTouchStart = (event) => {
      touchStartRef.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
      if (!touchStartRef.current) return;

      const touchEnd = event.touches[0].clientY;
      const delta = touchStartRef.current - touchEnd;

      setScaleAdjust(prevAdjust => {
        const newAdjust = prevAdjust + delta * 0.001;
        return Math.min(Math.max(newAdjust, -0.5), 1.5);
      });

      touchStartRef.current = touchEnd;
    };

    const handleTouchEnd = () => {
      touchStartRef.current = null;
    };

    container.addEventListener('wheel', handleScroll, { passive: false });
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchmove', handleTouchMove);
    container.addEventListener('touchend', handleTouchEnd);

    return () => {
      container.removeEventListener('wheel', handleScroll);
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <SolarSystemContainer ref={containerRef}>
      <CameraView style={cameraSpring}>
        <Star 
          radius={projects[0].radius} 
          onClick={() => onSelectPlanet(projects[0])}
        />
        {projects.map((project) => (
          project.id !== 0 && (
            <Planet
              key={project.id}
              ref={el => planetsRef.current[project.id] = el}
              style={{ backgroundColor: project.color }}
              onClick={() => onSelectPlanet(project)}
              radius={project.radius}
            />
          )
        ))}
      </CameraView>
    </SolarSystemContainer>
  );
}

export default SolarSystem;
