import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SolarSystem from './components/SolarSystem';
import PlanetInfo from './components/PlanetInfo';
import PlanetList from './components/PlanetList';

const AppContainer = styled.div`
  display: flex;
  height: 100dvh;
  width: 100dvw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const SidebarContainer = styled.div`
  display: flex;
  flex: 1;
  
  @media (orientation: portrait) {
    flex-direction: row;
  }
`;

const planetFiles = require.context('/public/projects', false, /\.md$/);

function App() {
  const [neoprojects, setNeoprojects] = useState([]);
  const [selectedPlanet, setSelectedPlanet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    const loadNeoprojects = async () => {
      const data = [];
      const promises = planetFiles.keys().map(async (link) => {
        const response = await fetch("projects" + link.substring(1)); // api for the get request
        const text = await response.text();
        data.push(text);
      });
      await Promise.all(promises);

      const projects = data
      .map(item => {
        const acc = {};
        const dataSplit = item.split("---");
        dataSplit[1].trim().split('\n').forEach(str => {
          const [key, value] = str.split(':').map(substr => substr.trim());
          if (key && value) {
            acc[key] = value;
          }
        });
        acc["info"] = dataSplit[2].trim();
        acc["id"] = parseFloat(acc["id"]);
        return acc;
      })
      .sort((a, b) => a.id - b.id);
      
      // console.log(projects);
      setNeoprojects(projects);
      var basePlanetIndex = -1;
      console.log(projects);
      for (const projectIndex in projects){
        basePlanetIndex += 1;
        if (projects[projectIndex]['name'] === "!!!About"){
          break;
        }
      }
      setSelectedPlanet(projects[basePlanetIndex]);
      setIsLoading(false);
    };
    loadNeoprojects();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AppContainer>
      <SolarSystem 
        projects={neoprojects} 
        selectedPlanet={selectedPlanet} 
        onSelectPlanet={setSelectedPlanet}
        isPortrait={isPortrait}
      />
      <SidebarContainer>
        <PlanetList projects={neoprojects} onSelectPlanet={setSelectedPlanet} isPortrait={isPortrait} />
        <PlanetInfo planet={selectedPlanet} isPortrait={isPortrait} />
      </SidebarContainer>
    </AppContainer>
  );
}

export default App;
